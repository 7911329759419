import { styled } from '@mui/material/styles';
import {
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from '@mui/material';
import { StyledTableRowInterface } from 'src/interfaces/layoutInterfaces';

export const StyledTableHead = styled(TableHead)({
  position: 'relative',
  'tr > th:first-of-type': {
    paddingLeft: 10,
  },
  'tr > th:last-of-type > div': {
    borderRight: 'none',
  },
});
export const StyledTableCell = styled(TableCell)<{ sort?: boolean, inner?: boolean }>(({
  sort,
  inner,
}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E9E9E9',
    color: '#676767',
    position: 'sticky',
    top: '0px',
    fontSize: 14,
    fontWeight: 600,
    padding: '12px 0px',
    zIndex: '2',
    ...(inner && {
      zIndex: '1',
    }),
    '& div': {
      borderRight: '2px solid #A0A0A0',
      padding: '0px 16px',
      '& span': {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#676767',
        cursor: 'auto',
      },
      ...(sort && {
        '& span': {
          cursor: 'pointer',
          color: '#676767',
        },
      }),
    },
    '&:last-child': {
      '& div': {
        borderRight: 'none',
        marginRight: 0,
      },
    },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    '&:first-of-type': {
      paddingLeft: 26,
    },
  },
}));
export const StyledTableRow = styled(TableRow)<StyledTableRowInterface>(({ isHover }) => ({
  borderBottom: 'none',
  ':hover': isHover && {
    backgroundColor: '#CBE7FE',
    cursor: 'pointer',
  },
}));
