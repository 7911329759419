import React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import CheckMarkIcon from 'public/svg/check-mark.svg';
import CrossIcon from 'public/svg/cross.svg';
import WarningIcon from 'public/svg/warning.svg';

const StatusTable = ({
  isActive, isMobile = false, haveEntity = false, havePlatform = false, isWarning = false, isStatus = true, message = 'Не созданы площадки или юр.лица',
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {isStatus && (isActive ? <CheckMarkIcon /> : <CrossIcon stroke="#676767" viewBox="3 0 24 24" />)}
      {isWarning && (!haveEntity || !havePlatform ? (
        <>
          <WarningIcon
            onClick={isMobile && anchorEl != null ? handlePopoverClose : handlePopoverOpen}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            id="mouse-over-popover"
            sx={!isMobile && {
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            onClick={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ padding: '12px' }}>
              {message}
            </Typography>
          </Popover>
        </>
      ) : null)}

    </Box>
  );
};

export default StatusTable;
