import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'src/store/main/slice';
import { actions as entityActions } from 'src/store/entity/slice';

const useModalWindowsState = (entityType?: string) => {
  const dispatch = useDispatch();
  let modalWindowsState = useSelector((store: any) => {
    if (entityType) {
      return store.entity[entityType].modalWindowsState;
    }
    return store.main.modalWindowsState;
  });

  if (entityType) {
    const setModalWindowsState = (payload: any) =>
      dispatch(entityActions.setModalWindowsState({ ...modalWindowsState, ...payload }));
    return { setModalWindowsState };
  }

  const setModalWindowsState = (payload: any) =>
    dispatch(actions.setModalWindowsState({ ...modalWindowsState, ...payload }));

  return { modalWindowsState, setModalWindowsState };
};

export default useModalWindowsState;
