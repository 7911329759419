/* eslint-disable react/display-name */
import { useAuth } from 'src/contexts/auth';
import RedirectPage from 'src/components/RedirectPage';
import { USER_ROLES } from 'src/constants';

const withAuth = (Component) => (allowedUsers) => (props: any) => {
  const { authData: { user } } = useAuth();

  const defaultRoleRoute = user.role === USER_ROLES.accountant
    ? '/expected_incomes'
    : '/';
  const showPage = allowedUsers.find((allowedUser) =>
    allowedUser.role === user.role
    && allowedUser.position === user.position);

  if (showPage) {
    return <Component {...props} />;
  }

  return <RedirectPage route={defaultRoleRoute} />;
};

export default withAuth;
