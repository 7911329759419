import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableCell, StyledTableHead } from 'src/components/Table/styled';
import { TableHeadProps } from 'src/interfaces';
import SortIcon from 'public/svg/sort.svg';

const TableHeadComponent = ({
  sortDirection, sortColumnName, onRequestSort, headCells, isCollapsedRows, inner,
}: TableHeadProps) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    const propertyName = headCells.find((cell) => cell.name === property).propertyName || property;
    onRequestSort(event, propertyName);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {isCollapsedRows && (
          <StyledTableCell inner={inner} />
        )}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.name}
            sortDirection={sortColumnName === headCell.name ? sortDirection : false}
            sort={headCell.showSortIcon}
            inner={inner}
          >
            <div>
              <TableSortLabel
                active={headCell.showSortIcon}
                direction={sortColumnName === headCell.name ? sortDirection : 'asc'}
                onClick={headCell.showSortIcon && createSortHandler(headCell.name)}
                IconComponent={SortIcon}
                hideSortIcon={!headCell.showSortIcon}
              >
                {headCell.title}
              </TableSortLabel>
            </div>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
export default TableHeadComponent;
