import React from 'react';
import Card from 'src/components/Cards/Card';
import InfiniteScroller from '../InfiniteScroller';

const Cards = ({ infiniteScrollerProps, сardProps }) => {
  const {
    dataCard,
    data,
    isTotalPrice,
    onCardClick,
    collapsedCardData,
  } = сardProps;
  const {
    entityType,
  } = infiniteScrollerProps;

  return (
    <InfiniteScroller entityType={entityType}>
      {data.map((item) => (
        <Card
          key={item.id}
          dataCard={dataCard}
          data={item}
          isTotalPrice={isTotalPrice}
          onCardClick={onCardClick}
          collapsedCardData={collapsedCardData}
        />
      ))}
    </InfiniteScroller>
  );
};

export default Cards;
