import orderBy from 'lodash/orderBy';
import React, { cloneElement } from 'react';
import {
  Box, Table, TableBody, TableContainer as TableContainerMui, Paper,
} from '@mui/material';

import { StyledTableCell, StyledTableRow } from 'src/components/Table/styled';
import { getAmountSum } from 'src/lib/helper';
import InfiniteScroller from 'src/components/InfiniteScroller';
import RenderTableCell from '../RenderTableCell';
import TableHeadComponent from '../TableHead';
import CollapsedRow from '../CollapsedTableRow';

const TableContainer = ({
  tableHeadProps,
  styledTableRowProps,
  collapsedRowChildrenProps,
  entityType,
  isTotalPriceOutside,
  data,
  innerTableComponentProps,
  collapsedRowsProps,
}) => {
  const {
    sortDirection,
    sortColumnName,
    handleRequestSort,
    headCells: columns,
    isCollapsedRows,
    inner,
  } = tableHeadProps;

  const {
    onRowClick,
    isHover,
  } = styledTableRowProps;

  const { collapsedTableData } = collapsedRowChildrenProps;

  const { isCollapsedRowsPopup } = collapsedRowsProps;

  const getAriaLabel = (entity) => (
    entity ? 'collapsed table' : 'default table');

  const orderedData = orderBy(data, sortColumnName, sortDirection);

  const renderTableBodyChildren = () => {
    if (entityType) {
      return orderedData
        .map((row) => {
          if (isCollapsedRows) {
            return (
              <CollapsedRow
                key={row.id}
                row={row}
                onRowClick={onRowClick}
                columns={columns}
                isCollapsedRowsPopup={isCollapsedRowsPopup}
              >
                {cloneElement(collapsedTableData, {
                  data: row,
                  ...innerTableComponentProps,
                })}
              </CollapsedRow>
            );
          }
          return (
            <StyledTableRow
              isHover={isHover}
              key={row.id}
              onClick={() => {
                onRowClick(row.id);
              }}
            >
              {columns.map((column) => (
                <StyledTableCell align="left" key={`${row.id}-${column.name}`}>
                  <RenderTableCell column={column} rowData={row} />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          );
        });
    }

    return orderedData
      .map((row) => (
        <StyledTableRow
          key={row.id}
          onClick={onRowClick ? () => onRowClick(row.id) : () => null}
          isHover={isHover}
        >
          {columns.map((column) => (
            <StyledTableCell align="left" key={`${row.id}-${column.name}`}>
              <RenderTableCell column={column} rowData={row} />
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ));
  };

  return (
    <TableContainerMui component={Paper}>
      <InfiniteScroller entityType={entityType}>
        <Table aria-label={getAriaLabel(entityType)}>
          <TableHeadComponent
            sortDirection={sortDirection}
            sortColumnName={sortColumnName}
            onRequestSort={handleRequestSort}
            headCells={columns}
            isCollapsedRows={isCollapsedRows}
            inner={inner}
          />
          <TableBody>
            {renderTableBodyChildren()}
          </TableBody>
        </Table>
      </InfiniteScroller>
      {isTotalPriceOutside && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px 0',
          flexWrap: 'no-wrap',
        }}
        >
          <Box sx={{ display: 'flex', padding: '12px 12px' }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
            >
              {getAmountSum(data[innerTableComponentProps?.dataInnerTabsName], data.amount, true)}
            </Box>
          </Box>
        </Box>
      )}
    </TableContainerMui>
  );
};

export default TableContainer;
