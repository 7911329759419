import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import MessageIcon from 'public/svg/message.svg';

const CommentComponent = ({
  comment, manager = false, accountant = false, title = 'Комментарии', isMobile = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {!isMobile && (
        <MessageIcon
          fill="#676767"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )}
      {isMobile && (
        <Box
          sx={{
            display: 'flex', alignItems: 'center', marginTop: '15px', padding: '5px',
          }}
          onClick={isMobile && anchorEl != null ? handlePopoverClose : handlePopoverOpen}
        >
          <MessageIcon fill="#006BCB" />
          <Typography sx={{ color: '#006BCB', marginLeft: '10px', fontSize: '14px' }}>
            {title}
          </Typography>
        </Box>

      )}
      <Popover
        id="mouse-over-popover"
        sx={!isMobile && {
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        onClick={handlePopoverClose}
        disableRestoreFocus
      >
        {accountant && (
          <Typography sx={{ padding: '12px 12px 4px 12px', color: '#676767' }}>
            Комментарий главного бухгалтера
          </Typography>
        )}
        {manager && (
          <Typography sx={{ padding: '12px 12px 4px 12px', color: '#676767' }}>
            Комментарий менеджера
          </Typography>
        )}
        <Typography sx={{ padding: '12px 12px 12px 12px' }}>
          {comment}
        </Typography>
      </Popover>
    </div>
  );
};

export default CommentComponent;
