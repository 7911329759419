import get from 'lodash/get';

const RenderTableCell = ({
  column, rowData,
}) => {
  if (column.render) {
    return <>{column.render(rowData)}</>;
  }
  return <>{get(rowData, column.name)}</>;
};

export default RenderTableCell;
