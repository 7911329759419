import router from 'next/router';
import { Box, Typography } from '@mui/material';

import PlusBlackIcon from 'public/svg/plus-black.svg';
import CustomButton from 'src/components/Button';
import useModalWindowsState from 'src/hooks/useModalWindowsState';

const EmptyPage = ({
  title, nameBtn = '', slug = '', isMobile = false,
}) => {
  const { setModalWindowsState } = useModalWindowsState();
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '50vh',
      flexDirection: 'column',
      position: 'absolute',
      padding: isMobile ? '0px 10px' : '0px',
    }}
    >
      <Typography
        component="h2"
        color="#676767"
        fontSize="24px"
        fontWeight="600"
        marginBottom="24px"
        textAlign="center"
      >
        {title}
      </Typography>
      {nameBtn && (
      <CustomButton
        onClick={() => {
          if (slug) {
            return router.push(slug);
          }
          return setModalWindowsState({ isCreateOpen: true });
        }}
        title={nameBtn}
        startIcon={<PlusBlackIcon />}
        sx={{ backgroundColor: 'white', color: '#191919' }}
      />
      )}
    </Box>
  );
};

export default EmptyPage;
