import { useState } from 'react';
import {
  Collapse, IconButton, TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import RenderTableCell from 'src/components/Table/RenderTableCell';
import { StyledTableCell } from 'src/components/Table/styled';

const CollapsedRow = ({
  row, columns, children, isCollapsedRowsPopup, onRowClick,
}: any) => {
  const onClick = (id) => onRowClick && onRowClick(id);
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, '&:hover': { backgroundColor: '#CBE7FE', cursor: 'pointer' } }} onClick={() => (!isCollapsedRowsPopup ? setOpen(!open) : null)}>
        <StyledTableCell sx={isCollapsedRowsPopup && { backgroundColor: 'white' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon sx={{ color: '#1E95FF' }} /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        {columns.map((column) => (
          <StyledTableCell
            align="left"
            key={column.name}
            onClick={() => onClick(row.id)}
          >
            <RenderTableCell column={column} rowData={row} />
          </StyledTableCell>
        ))}
      </TableRow>
      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse
          in={open}
          mountOnEnter
          unmountOnExit
        >
          {children}
        </Collapse>
      </StyledTableCell>
    </>
  );
};

export default CollapsedRow;
