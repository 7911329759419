import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, LinearProgress } from '@mui/material';
import { actions } from 'src/store/entity/slice';

const InfiniteScroller = ({ children, entityType }) => {
  const dispatch = useDispatch();

  const { nextListUrl, isLoadingMore } = useSelector((store: any) => ({
    nextListUrl: store.entity[entityType]?.nextListUrl,
    isLoadingMore: store.entity[entityType]?.isLoadingMore,
  }));
  const hasMore = !isLoadingMore && Boolean(nextListUrl);

  const loadMore = useCallback(() => {
    if (entityType) {
      dispatch(actions.loadOrderMoreListStart({ url: nextListUrl?.split('/').pop(), entityType }));
    }
  }, [hasMore, nextListUrl]);

  return entityType ? (
    <InfiniteScroll
      loadMore={loadMore}
      threshold={30}
      useWindow={false}
      hasMore={hasMore}
      loader={<Box><LinearProgress /></Box>}
    >
      {children}
    </InfiniteScroll>
  ) : (
    children
  );
};

export default InfiniteScroller;
