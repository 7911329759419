import React from 'react';
import { StubProps } from 'src/interfaces';
import EmptyPage from 'src/components/EmptyPage';

const Stub = ({
  list = [],
  isFilterEmpty = true,
  titleAfterFiltering = 'Данные не найдены',
  createButtonName = '',
  title,
  isMobile,
}: StubProps) => {
  const isListEmpty = list.length === 0;
  return (isListEmpty && isFilterEmpty ? (
    <EmptyPage isMobile={isMobile} title={title} nameBtn={createButtonName && createButtonName} />)
    : (isListEmpty && <EmptyPage isMobile={isMobile} title={titleAfterFiltering} />));
};

export default Stub;
