import React from 'react';

import TableComponent from 'src/components/Table';
import { PageContentInterface } from 'src/interfaces';
import { TableStubContentContainer } from 'src/components/Layout';
import Stub from 'src/components/Stub';
import Cards from 'src/components/Cards';

const PageContent = ({
  data,
  columns,
  onRowClick,
  entityType,
  isCollapsedRows = false,
  isCollapsedRowsPopup = false,
  isTotalPrice = false,
  isHover = true,
  innerTableComponentProps,
  collapsedData,
  isMobile,
  isFilterEmpty,
  createButtonName,
  title,
  dataCard,
  isTotalPriceOutside = false,
  isFilterOpen = false,
}: PageContentInterface) => (
  <TableStubContentContainer isFilterOpen={isFilterOpen}>
    <Stub
      isMobile={isMobile}
      isFilterEmpty={isFilterEmpty}
      list={data}
      createButtonName={createButtonName}
      title={title}
    />
    {
      isMobile ? (
        <Cards
          infiniteScrollerProps={{
            entityType,
          }}
          сardProps={{
            dataCard,
            data,
            isTotalPrice,
            onCardClick: onRowClick,
            collapsedCardData: collapsedData,
          }}
        />
      ) : (
        <TableComponent
          data={data}
          columns={columns}
          onRowClick={onRowClick}
          entityType={entityType}
          isCollapsedRows={isCollapsedRows}
          isCollapsedRowsPopup={isCollapsedRowsPopup}
          isTotalPriceOutside={isTotalPriceOutside}
          isHover={isHover}
          innerTableComponentProps={innerTableComponentProps}
          collapsedTableData={collapsedData}
        />
      )
}
  </TableStubContentContainer>
);

export default PageContent;
