import { Box, IconButton } from '@mui/material';
import React, { cloneElement, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { MobileCard, StyledCardContainer } from 'src/components/Layout';
import { CardPropsInterface } from 'src/interfaces';
import StatusTableContractors from 'src/components/StatusTable/index';
import CommentComponent from 'src/components/Comment';

const Card = ({
  data,
  onCardClick,
  dataCard,
  isTotalPrice,
  collapsedCardData,
}: CardPropsInterface) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const isWarningButton = (data?.have_entity !== undefined && data?.have_platform !== undefined);
  return (
    <StyledCardContainer>
      <MobileCard>
        <Box
          sx={{
            padding: '8px 12px',
            ...(isOpenCollapse && {
              backgroundColor: '#E9E9E9',
            }),
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => (onCardClick(data.id))}
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{
                  display: 'flex', width: 'fit-content', maxWidth: '70%', justifyContent: 'space-between',
                }}
                >
                  <Box sx={{ color: '#191919', whiteSpace: 'nowrap', marginRight: '10px' }}>ID: {data.id}</Box>
                  <Box sx={{ color: '#676767', whiteSpace: 'nowrap' }}>от {data.created}</Box>
                </Box>
                {!data[dataCard?.collapsedDataName] && <Box sx={{ color: '#0E79EC', textAlign: 'end' }}>{data?.status}</Box>}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  {dataCard?.dataKeys.map((keyCard) => (
                    <Box key={data?.id} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <Box
                        sx={{ color: '#676767', margin: '0px 8px 0px 0px' }}
                      >
                        {keyCard?.label}:
                      </Box>
                      <Box>
                        {data[keyCard?.key]}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box>
              {!!data[dataCard.collapsedDataName] && collapsedCardData && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                  sx={{
                    width: '33px',
                    height: '33px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >{isOpenCollapse
                  ? <KeyboardArrowUpIcon sx={{ color: '#1E95FF' }} />
                  : <KeyboardArrowDownIcon sx={{ color: '#676767' }} />}
                </IconButton>
              )}
            </Box>
            {isWarningButton && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{
                    width: '33px',
                    height: '33px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <StatusTableContractors
                    isMobile
                    isActive={false}
                    isStatus={false}
                    haveEntity={data?.have_entity}
                    havePlatform={data?.have_platform}
                    isWarning
                  />
                </IconButton>

              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '10px' }}>
            {data?.comment && (
              <Box sx={{ marginRight: '10px' }}>
                <CommentComponent isMobile manager comment={data?.comment} />
              </Box>
            )}
            {data?.accountant_comment && (
            <Box sx={{ marginRight: '10px' }}>
              <CommentComponent isMobile accountant title="Гл.бухгалтер" comment={data?.accountant_comment} />
            </Box>
            )}
            {data?.manager_comment && (
            <Box sx={{ marginRight: '10px' }}>
              <CommentComponent isMobile manager title="Менеджер" comment={data?.manager_comment} />
            </Box>
            )}
          </Box>
        </Box>
        {collapsedCardData && (
          cloneElement(collapsedCardData, {
            data,
            dataCard,
            isTotalPrice,
            isOpenCollapse,
            setIsOpenCollapse,
          }))}
      </MobileCard>
    </StyledCardContainer>
  );
};

export default Card;
