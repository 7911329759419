import { ReactElement } from 'react';
import { StyledButton } from 'src/components/Layout';

interface CustomButtonProps {
  title: string | ReactElement,
  variant?: 'text' | 'outlined' | 'contained',
  startIcon?: ReactElement;
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean
  sx?: object,
  onClick?: () => void,
  light?: boolean,
  component?: any,
  isMobile?: boolean,
  balance?: number,
}

const CustomButton = ({
  title, startIcon, variant, type, disabled, sx, onClick, isMobile, ...rest
}: CustomButtonProps) => (
  <StyledButton
    isMobile={isMobile}
    startIcon={startIcon}
    variant={variant}
    type={type}
    disabled={disabled}
    sx={sx}
    onClick={onClick}
    {...rest}
  >
    {title}
  </StyledButton>
);
export default CustomButton;
