import React, { useState } from 'react';

import { InnerTableProps } from '../InnerTable';
import TableContainer from './TableContainer';

interface TableComponentInterface {
  data: any,
  columns: any,
  onRowClick?: (id: number) => void,
  entityType?: string,
  isHover?: boolean
  isCollapsedRows?: boolean,
  innerTableComponentProps?: InnerTableProps,
  collapsedTableData?: any,
  isCollapsedRowsPopup?: boolean,
  isTotalPriceOutside?:boolean
  inner?: boolean,
}

const TableComponent = ({
  data,
  columns,
  onRowClick,
  entityType,
  isCollapsedRows = false,
  isCollapsedRowsPopup = false,
  isHover = true,
  innerTableComponentProps,
  collapsedTableData,
  isTotalPriceOutside = false,
  inner = false,
}: TableComponentInterface) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumnName, setSortColumnName] = useState<string>('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Record<string, any>,
  ) => {
    const isAsc = sortColumnName === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumnName(property);
  };

  return (
    <TableContainer
      isTotalPriceOutside={isTotalPriceOutside}
      entityType={entityType}
      tableHeadProps={{
        sortDirection,
        sortColumnName,
        onRequestSort: handleRequestSort,
        headCells: columns,
        isCollapsedRows,
        inner,
      }}
      styledTableRowProps={{
        onRowClick,
        isHover,
      }}
      data={data}
      innerTableComponentProps={
          innerTableComponentProps
        }
      collapsedRowChildrenProps={{
        collapsedTableData,
      }}
      collapsedRowsProps={{
        isCollapsedRowsPopup,
      }}
    />
  );
};

export default TableComponent;
