import Head from 'next/head';

interface MetaProps {
  title?: string,
  description?: string,
  ogTitle?: string,
  ogDescription?: string,
  ogImage?: string,
  url?: string,
}

const Meta = ({
  title, description, ogTitle, ogDescription, ogImage, url,
}: MetaProps) => (
  <Head>
    <title>{title}</title>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta property="og:type" content="website" />
    <link rel="icon" href="/Favicon.ico" />
    {/* <link rel="manifest" href="/manifest.json" /> */}
    <meta name="description" content={description} />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDescription} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={ogImage} />
    <meta name="twitter:site" content={url} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={ogTitle} />
    <meta name="twitter:description" content={ogDescription} />
    <meta name="twitter:image" content={ogImage} />
  </Head>
);

Meta.defaultProps = {
  title: 'Utrade',
  description: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: '',
  url: process.env.NEXT_PUBLIC_HOST,
};

export default Meta;
